<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-card class="cardClassDetailInfo" :noHeader="true">
        <template slot="card-detail">
          <div class="col-3">
            <!-- 사업장 -->
            <c-label-text title="LBLPLANT" :value="param.plantName"></c-label-text>
          </div>
          <div class="col-3">
            <!-- 설비유형 -->
            <c-label-text title="LBL0001720" :value="param.equipmentTypeName"></c-label-text>
          </div>
          <div class="col-3">
            <!-- 설비관리번호 -->
            <c-label-text title="LBL0001727" :value="param.equipmentNo"></c-label-text>
          </div>
          <div class="col-3">
            <!-- 설비명 -->
            <c-label-text title="LBL0001725" :value="param.equipmentName"></c-label-text>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <!-- 설비점검이력 -->
      <c-table
        ref="table1"
        title="LBL0003601"
        :columns="grid.columns"
        :data="grid.data"
        gridHeight="300px"
      >
        <template v-slot:customArea="{ props }">
          <template v-if="props.row['checkStatusCd']">
            <q-chip
              outline square
              :color="setTagColor(props.row.checkStatusCd)"
              :clickable="true"
              :class="props.row.checkStatusCd ==='MCSC000020' ? 'blinking' : null"
              text-color="white"
              @click="btnClicked(props.row)">
              <q-avatar v-if="props.row.checkStatusCd==='MCSC000020'" icon="alarm" color="red" text-color="white" />
              {{setTagName(props.row.checkStatusCd)}}
            </q-chip>
          </template>
        </template>
      </c-table>
      <!-- 설비정비이력 -->
      <c-table
        ref="table2"
        title="LBL0003602"
        :columns="grid2.columns"
        :data="grid2.data"
        gridHeight="300px"
      >
        <template v-slot:customArea="{ props }">
          <template v-if="props.row['maintenanceStatusCd']">
            <q-chip
              :color="setTagColor(props.row.maintenanceStatusCd)"
              :class="props.row.maintenanceStatusCd ==='MCSC000020' ? 'blinking' : null"
              :clickable="true"
              text-color="white"
              @click="btnClicked2(props.row)">
              <q-avatar v-if="props.row.maintenanceStatusCd==='MCSC000020'" icon="alarm" color="red" text-color="white" />
              {{setTagName(props.row.maintenanceStatusCd)}}
            </q-chip>
          </template>
        </template>
      </c-table>
      <c-dialog :param="popupOptions"></c-dialog>
    </div>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'equipment-inspect',
  props: {
    param: {
      type: Object,
      default: () => ({
        equipmentCd: '',
        equipmentNo: '',
        plantName: '',
        plantCd: '',
        processCds: [],
        equipmentTypeName: '',
        equipmentName: '',
      }),
    },
  },
  data() {
    return {
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      editable: true,
      searchParam: {
        plantCd: null,
        equipmentCheckKindCd: null,
        checkStatusCd: null,
        startYmd: '',
        endYmd: '',
        noPlanResultEnrollFlag: null,
        equipmentTypeCd: '',
        inspectionCycleCd: '',
        grade: '',
        equipmentCd: this.param.equipmentCd,
      },
      checkTypeCdItems: [], // 점검유형
      checkStepCdItems: [], // 점검진행상태
      checkNoPlanItems: [
        { code: 'Y', codeName: '무계획' },
        { code: 'N', codeName: '계획' }
      ], // 점검진행상태
      colorItems: [
        { stepperMstCd: 'MCSC000005', stepperMstNm: '계획수립', colorClass: 'blue' },
        { stepperMstCd: 'MCSC000010', stepperMstNm: '점검중', colorClass: 'orange' },
        { stepperMstCd: 'MCSC000015', stepperMstNm: '점검완료', colorClass: 'green' },
        { stepperMstCd: 'MCSC000020', stepperMstNm: '지연', colorClass: 'red' },
      ],
      grid: {
        columns: [
          {
            name: "checkStatusCd",
            field: "checkStatusCd",
            // 진행상태
            label: "LBLPROGRESS",
            style: 'width:100px',
            type: 'custom',
            align: "center",
            sortable: true,
          },
          {
            name: "checkScheduleDate",
            field: "checkScheduleDate",
            // 점검예정일
            label: "LBL0003603",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "checkScheduleUserName",
            field: "checkScheduleUserName",
            style: 'width:100px',
            // 점검예정자
            label: "LBL0003604",
            align: "center",
            sortable: true,
          },
          {
            name: "checkDeptName",
            field: "checkDeptName",
            style: 'width:100px',
            // 점검부서
            label: "LBL0003605",
            align: "center",
            sortable: true,
          },
          {
            name: "checkDate",
            field: "checkDate",
            style: 'width:100px',
            // 점검완료일
            label: "LBL0003606",
            align: "center",
            sortable: true,
          },
          {
            name: "checkResultName",
            field: "checkResultName",
            style: 'width:80px',
            // 점검결과
            label: "LBL0003607",
            align: "center",
            sortable: true,
          },
          {
            name: "equipmentCheckKindNm",
            field: "equipmentCheckKindNm",
            // 점검유형
            label: "LBL0003608",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "inspectionCycleName",
            field: "inspectionCycleName",
            // 점검주기
            label: "LBL0003609",
            style: 'width:80px',
            align: "center",
            sortable: true,
          },
          {
            name: "relatedLawsName",
            field: "relatedLawsName",
            // 관련법규
            label: "LBL0003610",
            style: 'width:250px',
            align: "left",
            sortable: true,
          },
          {
            name: "noPlanName",
            field: "noPlanName",
            // 계획/무계획
            label: "LBL0003611",
            style: 'width:80px',
            align: "center",
            sortable: true,
          },
        ],
        data: [],
      },
      grid2: {
        columns: [
          {
            name: "maintenanceStatusCd",
            field: "maintenanceStatusCd",
            // 진행상태
            label: "LBLPROGRESS",
            style: 'width:100px',
            align: "center",
            sortable: true,
            type: 'custom',
          },
          {
            name: "maintenanceDueDate",
            field: "maintenanceDueDate",
            // 정비예정일
            label: "LBL0003612",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "maintenanceDueUserName",
            field: "maintenanceDueUserName",
            style: 'width:100px',
            // 정비예정자
            label: "LBL0003613",
            align: "center",
            sortable: true,
          },
          {
            name: "memDeptEntrantUserName",
            field: "memDeptEntrantUserName",
            style: 'width:110px',
            // 정비부서입회자
            label: "LBL0003614",
            align: "center",
            sortable: true,
          },
          {
            name: "maintenanceUserName",
            field: "maintenanceUserName",
            style: 'width:100px',
            // 실제정비자
            label: "LBL0003615",
            align: "center",
            sortable: true,
          },
          {
            name: "maintenanceDate",
            field: "maintenanceDate",
            // 정비완료일
            label: "LBL0003616",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "inspResultAfterMemName",
            field: "inspResultAfterMemName",
            // 정비결과
            label: "LBL0003617",
            style: 'width:80px',
            align: "center",
            sortable: true,
          },
          {
            name: "equipmentCheckTypeName",
            field: "equipmentCheckTypeName",
            // 정비유형
            label: "LBL0003618",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "pmCycleName",
            field: "pmCycleName",
            // 정비주기
            label: "LBL0003619",
            style: 'width:80px',
            align: "center",
            sortable: true,
          },
          {
            name: "relatedLawsName",
            field: "relatedLawsName",
            // 관련법규
            label: "LBL0003610",
            style: 'width:250px',
            align: "left",
            sortable: true,
          },
          {
            name: "noPlanName",
            field: "noPlanName",
            // 계획/무계획
            label: "LBL0003611",
            style: 'width:80px',
            align: "center",
            sortable: true,
          },
        ],
        data: [],
      },
      listUrl: '',
      listUrl2: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.listUrl = selectConfig.sop.min.equipment.plan.list.url;
      this.listUrl2 = selectConfig.sop.min.maintenance.plan.list.url;
      this.getList();
      this.getList2();
    },
    getList() {
      // 전체조회
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    getList2() {
      // 전체조회
      this.$http.url = this.listUrl2;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid2.data = _result.data;
      },);
    },
    btnClicked(row) {
      this.popupOptions.title = 'LBL0003620'; // 설비점검결과 상세
      this.popupOptions.param = {
        minEquipmentCheckId: row.minEquipmentCheckId ? row.minEquipmentCheckId : '',
        title: '설비점검결과',
        selectData: [],
        noPlan: 'N',
      };
      // this.popupOptions.target = () =>
      //   import(`${"@/pages/sop/mim/inspection/equipmentResultDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = false;
      this.popupOptions.width = '90%';
      this.popupOptions.closeCallback = this.closePopup;
    },
    btnClicked2(row) {
      this.popupOptions.title = 'LBL0003621'; // 설비정비결과 상세
      this.popupOptions.param = {
        minEquipmentMaintenanceId: row.minEquipmentMaintenanceId ? row.minEquipmentMaintenanceId : '',
        selectData: [],
      };
      // this.popupOptions.target = () =>
      //   import(`${"@/pages/sop/mim/maintenance/equipmentMaintenanceResultDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = false;
      this.popupOptions.width = '90%';
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    setTagColor(data) {
      if (this.colorItems) {
        let color = this.$_.find(this.colorItems, { code: data }) || this.$_.find(this.colorItems, { stepperMstCd: data })
        return color ? color.attrVal1 || color.colorClass : '';
      } else {
        return ''
      }
    },
    setTagName(data) {
      if (this.colorItems) {
        let color = this.$_.find(this.colorItems, { code: data }) || this.$_.find(this.colorItems, { stepperMstCd: data })
        return color ? color.codeName || color.stepperMstNm : '';
      } else {
        return ''
      }
    },
  }
};
</script>